@use '~@angular/material' as mat;
@import "~@angular/material/theming";
@include mat.core();

$black-87-opacity: rgba(0, 0, 0, 0.87);
$mat-primary: (
  50: #e6eff3,
  100: #bfd6e1,
  200: #95bbcd,
  300: #6b9fb9,
  400: #4b8baa,
  500: #007AFF,
  600: #266e93,
  700: #206389,
  800: #1a597f,
  900: #10466d,
  A100: #a2d4ff,
  A200: #6fbdff,
  A400: #3ca5ff,
  A700: #239aff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);
$mat-accent: (
  50: #fce4ec,
  100: #f8bcd0,
  200: #f48fb1,
  300: #f06292,
  400: #ec407a,
  500: #007AFF,
  600: #e61a5b,
  700: #e31651,
  800: #df1247,
  900: #d90a35,
  A100: #ffffff,
  A200: #ffd0d8,
  A400: #ff9dae,
  A700: #ff8498,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$mat-error: (
  500: #ff7f50,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$mat-warn: (
  50: #fce4ec,
  100: #f8bcd0,
  200: #f48fb1,
  300: #f06292,
  400: #ec407a,
  500: #ff8333,
  600: #e61a5b,
  700: #e31651,
  800: #df1247,
  900: #d90a35,
  A100: #ffffff,
  A200: #ffd0d8,
  A400: #ff9dae,
  A700: #ff8498,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$mat-secondary: (
  500: #20262D,
  contrast: ()
);

$seq-primary: mat.define-palette($mat-primary);
$seq-accent: mat.define-palette($mat-accent);
$seq-warn: mat.define-palette($mat-warn);
$seq-error: mat.define-palette($mat-error);
$seq-theme: mat.define-light-theme($seq-primary, $seq-accent);
$primary-dark: darken(map-get($mat-primary, 500), 8%);
$background: map-get($seq-theme, background);
$foreground: map-get($seq-theme, foreground);
$myWhite: rgba(255, 255, 255, 0.9);
$berwynRed: #e52b39;
$berwynGreen: #4ab16c;
$seq-secondary: mat.define-palette($mat-secondary);

.seq-theme {
  @include mat.all-component-themes($seq-theme);

  .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
  .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
    color: #ff7f50;
    font-weight: 400;
  }
  .mat-error {
    color: #ff7f50;
    font-weight: 400;
    line-height: 1.3em;
    font-size: 14px!important;
    margin-top: 10px;
  }
  .mat-form-field.mat-form-field-invalid .mat-form-field-label{
    color: #ff7f50;
  }
  .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick{
    color: #ff7f50;
  }
  .mat-form-field-invalid .mat-input-element {
    caret-color: #ff7f50;
  }

  .white {
    color: $myWhite;
    font-weight: 400;
    .mat-form-field-ripple,
    .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
    .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent,
    .mat-form-field-underline,
    .mat-form-field.mat-focused .mat-form-field-ripple {
      background-color: $myWhite;
      font-weight: 400;
    }
    .mat-stroked-button,
    .mat-stroked-button.mat-primary,
    .mat-stroked-button:not([class*="mat-elevation-z"]) {
      margin: 0 14px 14px 0;
      color: $myWhite;
      border-color: $myWhite;
    }

    .mat-stroked-button[disabled][disabled] {
      margin: 0px 14px 14px 0;
      color: rgba(255, 255, 255, 0.6);
      border-color: rgba(255, 255, 255, 0.6);
    }
    .mat-form-field {
      display: flex;
      flex-direction: row;
      margin: 16px 0;
      .hidden {
        display: none;
      }
    }
    p,
    h1,
    h2,
    h3,
    a {
      color: $myWhite;
      font-weight: 400;
      text-decoration: none;
    }
    h3 {
      font-size: 18px;
      font-weight: bold;
    }
    h4{
      font-weight: bold;
    }
    .mat-input-element {
      width: 100%;
      color: $myWhite;
      caret-color: $myWhite;
      font-weight: 400;
    }
    .mat-form-field-label .mat-form-field-required-marker {
      color: $myWhite;
      font-weight: 400;
    }
    .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
    .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
      color: #ff7f50 !important;
      font-weight: 400;
    }
    .mat-error {
      color: #ff7f50 !important;
      font-weight: 400;
      font-size: 14px!important;
    }
    .mat-toolbar {
      background: none;
    }
    .mat-form-field.mat-form-field-invalid .mat-form-field-label {
      color: #ff7f50 !important;
    }
  }
  .mat-snack-bar-container.white {
    color: #ff7f50;
    .mat-button {
      color: #ff7f50;
    }
  }

  .mat-nav-list .mat-list-item:focus {
    background: none;
  }

  .mat-card[class*="mat-elevation-z"],
  .mat-dialog-container,
  .mat-select-panel,
  .mat-card,
  .mat-card:not([class*="mat-elevation-z"]),
  .mat-menu-panel {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 16px;
    background: #fff;
    border-radius: 2px;
  }

  .mat-cell,
  .mat-footer-cell {
    font-size: 14px;
    padding-right: 12px;
    margin: 12px 0;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
  }

  .mat-header-cell {
    padding-right: 12px;
    font-size: 13px;
    font-weight: 600;
  }

  .mat-header-row {
    min-height: 42px;
  }

  .mat-mini-fab[disabled][disabled] {
    background-color: #ffffff;
    .mat-icon.mat-primary {
      color: rgba(0, 0, 0, 0.4);
    }
  }

  .mat-form-field {
    margin: 8px 0;
  }

  .mat-form-field-infix {
    border-top: 0;
  }

  .mat-form-field-appearance-outline .mat-form-field-label {
    margin-top: 0;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline {
    color: rgba(0, 0, 0, 0.42);
  }
  .mat-form-field-appearance-outline .mat-form-field-outline-start {
    border-left: 2px solid;
    border-top: 2px solid;
    border-bottom: 2px solid;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline-end,
  .mat-form-field-appearance-outline._mat-animation-noopable .mat-form-field-outline-end {
    border-right: 2px solid;
    border-top: 2px solid;
    border-bottom: 2px solid;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline-gap {
    border-top: 2px solid;
    border-bottom: 2px solid;
  }
  .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
    transform: translateY(10%);
  }

  .mat-form-field-appearance-outline .mat-form-field-prefix,
  .mat-form-field-appearance-outline .mat-form-field-suffix {
    top: 0.5em;
    color: rgba(0, 0, 0, 0.56);
  }

  :not(.mat-form-field-appearance-legacy).mat-form-field .mat-form-field-prefix .mat-icon-button,
  :not(.mat-form-field-appearance-legacy).mat-form-field .mat-form-field-suffix .mat-icon-button {
    display: inline-block;
    height: 1.5em;
    width: 1.5em;
  }

  .mat-select-panel mat-option.mat-option {
    height: unset;
  }

  .mat-option-text.mat-option-text {
    line-height: 26px;
    margin: 12px 0;
    white-space: normal;
  }

  .no-spacing .mat-form-field-wrapper {
    margin: 0;
    padding-bottom: 0;
  }

  .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
    color: rgba(0, 0, 0, 0.12);
  }

  .mat-input-element:disabled,
  .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after,
  .mat-select-disabled .mat-select-value {
    color: rgba(0, 0, 0, 0.67);
  }


  .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
    color: rgba(0, 0, 0, 0.56);
  }

  .set-password-input-width {
    .mat-form-field-infix {
      width: 200px;
    }
  }
  /*custom style for storegene*/
  .mat-card{
    color: #20262D;
  }
  .ml30{
    margin-left: 30px;
  }
  .mt5{
    margin-top: 5px!important;
  }
  .mt15{
    margin-top: 15px!important;
  }
  .mt20{
    margin-top: 20px;
  }
  .mt30{
    margin-top: 30px;
  }
  a{
    color: #007AFF;
  }
  .divider{
    border-bottom: 1px solid #B5B5B5;
    display: block;
    width: 100%;
  }
  .mat-select{
    padding: 0;
    padding-bottom: 0;
    + .mat-error{
      margin-top: 10px;
    }
  }
  .mat-menu-panel {
    background: #001A36;
    .mat-menu-content{      
      .arrow-up{
        width: 0; 
        height: 0; 
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #001A36;
        display: block;
        position: absolute;
        top: -9px;
        right: 9px;
      }
      .mat-icon.mat-accent{
        color: #fff;
      }
      .mat-menu-item{
        color: #fff;
        &:hover{
          background: rgba(250, 250, 250, 0.1);
        }
      }
    }
  }
}
/*custom style for storegene*/
.float-left{
  float: left!important;
}
.float-right{
  float: right!important;
}
.text-right{
  text-align: right;
}
.nopadding{
  padding: 0!important;
}
.nopadbottom{
  padding-bottom: 0!important;
}
.nopadtop{
  padding-top: 0!important;
}
.pad30{
  padding: 30px;
}
.cust-breadcumb{
  .page-nav{
    display: none!important;
  }
}
.change-password{
  background: transparent!important;
  padding: 0!important;
  box-shadow: none!important;
  .cust-align{
    text-align: center;
    margin: auto;
    form{
      margin-top: 10px!important;
      .mat-form-field{
        width: 100%;
      }
    }
  }
}
.sign-in-container{
  form{
    margin-top: 50px;
    .mat-form-field-flex, .ng-valid{
      .mat-form-field-label-wrapper{
        top: -3.1em;
        .mat-form-field-label{
          top: 30px;
          transition: none!important;
          transform: none!important;
          font-size: 16px!important;
          font-weight: bold;
          .mat-form-field-required-marker{
            color: #ff7f50!important;
          }
          .white{
            font-weight: bold;
          }
        }
      }
    }
    .mat-focused{
      .mat-form-field-flex{
        .mat-form-field-label-wrapper{
          top: -3.1em!important;
          .mat-form-field-label{
            transition: none!important;
            transform: none!important;
            font-size: 16px!important;
          }   
        }
        .mat-input-element{
          outline: 3px solid #007AFF;
          box-shadow: inset 0 0 0 2px;
        }
      }
    }
    .mat-form-field{
      margin: 40px 0 30px 0!important;
      .mat-form-field-wrapper{
        width: 100%!important;
        padding-bottom: 0.6em;
        .mat-form-field-flex{
          .mat-form-field-infix{
            padding: 0.4375em 0;
          }
          .mat-input-element{
            color: #20262D!important;
            background: #fff;
            min-height: 30px;
            padding: 5px 10px;
          }
          .mat-input-element::placeholder{
            -webkit-text-fill-color: #B5B5B5!important;
          }
        }
        .mat-form-field-underline{
          display: none!important;
        }
      }
    }
    .mat-form-field.mat-form-field-invalid{
      .mat-form-field-wrapper{
        .mat-form-field-flex{
          .mat-form-field-label{
            color: #20262D!important;
          }
          // .mat-input-element{
          //   border: 2px solid #f44336!important;
          // }
        }
      }
    } 
    .mat-form-field.mat-form-field-hide-placeholder{
      .mat-form-field-wrapper{
        .mat-form-field-flex{
          .mat-form-field-infix{
            .mat-input-element::placeholder{
                -webkit-text-fill-color: #B5B5B5!important;
            }
          }
        }
      }
    }
  }
}

.form-container{
  form{
    margin-top: 30px;
    .mat-form-field-flex, .ng-valid{
      .mat-form-field-label-wrapper{
        top: -3.1em;
        .mat-form-field-label{
          top: 30px;
          transition: none!important;
          transform: none!important;
          font-size: 16px!important;
          color: #20262D!important;
          font-weight: bold;
          .mat-form-field-required-marker{
            color: #ff7f50!important;
          }
        }
      }
    }
    .mat-form-field{
      margin: 15px 0!important;
      width: 100%;
      .mat-form-field-wrapper{
        width: 100%!important;
        padding-bottom: 1em;
        .mat-form-field-subscript-wrapper{
          padding: 0!important;
          margin-top: 0!important;
          .mat-error{
            font-size: 14px!important;
          }
        }
        .mat-form-field-flex{
          padding: 0!important;
            .mat-form-field-appearance-outline, .mat-form-field-outline-thick, .mat-form-field-outline-start,
            .mat-form-field-appearance-outline.mat-form-field-invalid, .mat-form-field-outline{
            display: none!important;
          }
          .mat-form-field-infix{
            padding: 0.4375em 0;
            padding-bottom: 0;
            .mat-select{
              .mat-select-trigger{
                color: #20262D!important;
                background: #fff;
                min-height: 40px;
                padding: 5px 10px;
                border: 2px solid #20262D!important;
                width: -webkit-fill-available;
                .mat-select-value{
                  padding: 0!important;
                  vertical-align: middle;
                }
                .mat-select-arrow{
                  color: #20262D!important;
                }
              }
            }
            // .mat-select.mat-select-invalid{
            //   .mat-select-arrow{
            //     color: #f44336!important;
            //   }
            //   .mat-select-trigger{
            //     border-color: #f44336!important;
            //   }
            // }
          }
          .mat-input-element{
            color: #20262D!important;
            background: #fff;
            min-height: 27px;
            padding: 5px 10px;
            border: 2px solid #20262D!important;
            width: -webkit-fill-available;
          }
          .mat-input-element::placeholder{
            -webkit-text-fill-color: #B5B5B5!important;
          }
        }
        .mat-form-field-underline{
          display: none!important;
        }
      }
    }
    .mat-form-field.mat-form-field-invalid{
      .mat-form-field-wrapper{
        .mat-form-field-flex{
          .mat-form-field-label{
            color: #20262D!important;
          }
        }
      }
    } 
    .mat-focused{
      .mat-form-field-flex{
        .mat-form-field-label-wrapper{
          top: -3.1em!important;
          .mat-form-field-label{
            transition: none!important;
            transform: none!important;
            font-size: 16px!important;
          }   
        }
        .mat-input-element{
          outline: 3px solid #007AFF;
          box-shadow: inset 0 0 0 2px;
        }
        .mat-select{
          .mat-select-trigger{
            outline: 3px solid #007AFF;
            box-shadow: inset 0 0 0 2px;
          }
        }
        .mat-select.mat-select-invalid{
          .mat-select-trigger{
            border-color: #20262D!important;
            box-shadow: inset 0 0 0 2px;
          }
        }
      }
    }
    .mat-form-field.mat-form-field-hide-placeholder{
      .mat-form-field-wrapper{
        .mat-form-field-flex{
          .mat-form-field-infix{
            .mat-input-element::placeholder{
                -webkit-text-fill-color: #B5B5B5!important;
            }
          }
        }
      }
    }
  }
}
#conventional-factor{
  form{
    .mat-form-field-flex, .ng-valid{
      .mat-input-element, .mat-select-arrow-wrapper{
        font-size: 16px;
      }
      .mat-form-field-label-wrapper{
        .mat-form-field-label{
          top: 42px;
        }

      }
    }
    .cust-lael-style{
      margin-top: 15px;
      .mat-form-field-label{
        white-space: inherit;
        top: 28px!important;
        width: auto!important;
      }
    }
  }
}
.mat-dialog-container{
  padding: 30px!important;
  height: fit-content!important;
  .mat-form-field-wrapper, .mat-form-field{
    margin: 0!important;
  }
  .mat-form-field-subscript-wrapper{
    position: relative!important;
  }
}
.align-right{
  float: right;
}
.close-alignment{
  float: right;
  margin-top: 2px;
  a{
    cursor: pointer;
    mat-icon{
      border: 2px solid;
    }
  }
}
.mat-tertiary{
  color: #20262D;
}
.card-header-value{
  color: #007AFF;
  font-size: 19px;
  font-weight: bold;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.mat-table{
  .mat-header-row{
    .mat-header-cell{
      color: #20262D;
      font-size: 16px;
    }
  }
  .mat-row{
    border: none;
    .mat-cell{
      color: #20262D;
      font-size: 16px;
      margin: 0;
    }
    &:nth-of-type(even){
      background: #f6f6f6;
    }
  }
}
.date-picker-cust{
  .mat-form-field-infix{
    width: -webkit-fill-available!important;
  }
  .mat-form-field-suffix{
    position: absolute!important;
    top: 16px!important;
    right: 8px;
  }  
}

